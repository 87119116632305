<template>
  <!-- <div>
    <img :src="imageSrc" :alt="alt" />
  </div> -->
  <div class="anyclass">test</div>
  <h1>SADSADiygyufha</h1>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "https://picsum.photos/600/400",
    },
  },
  computed: {
    imageSrc() {
      return this.src ? this.src : this.placeholder;
    },
  },
};
</script>

<style scoped>
img {
  border: 3px solid black;
  border-radius: 20px;
}

.anyclass {
  height: 230px;
  width: 50px;
  background-color: red;
  color: brown;
}
</style>
